.error_code{
    border-color: red !important;
	color: #000;
    outline: 0;
    transition: .5s !important;
}
.good_code{
    border-color: lawngreen !important;
    color: #000;
    outline: 0;
    transition: .5s !important;
}