@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

a{
  color:rgb(254, 211, 23) !important;
  transition: 0.4s;
}
a:hover{
  color: white !important;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar{
  width:0.3rem;
}
body::-webkit-scrollbar-track{
  background: #fed317;
}
body::-webkit-scrollbar-thumb{
  background: #0f63a8;
}
.Login{
  color:rgb(254, 211, 23) !important;
  transition: 0.4s;
  cursor: pointer;
}
.Login:hover{
  color: white !important;
}
.navbar{
  padding: 0 1rem 0 0 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.dropdown-menu{
  background-color: #03255F !important;
}
.dropdown-item:hover{
  background-color:#006ce0 !important;
}
.bg-primary{
  background-color: #0f63a8 !important;
}