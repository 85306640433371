.sideNav{
    top:0;
    width: 6rem;
    height:100%;
    position:fixed;
    background-color: #03255F;
    transition: 200ms ease;
    box-shadow: 10px 0px 8px -5px rgba(0,0,0,0.75);
    z-index: 999;
}
.sideNav a{
    text-decoration: none !important;
}
.sideNav-nav{
    list-style: none;
    padding: 140px 0 0 0;
    margin: 0 ;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
}
.sideNav-item{
    width: 100%;
    
}
.sideNav-item:last-child{
    margin-top: auto;
}
.sideNav-link{
    display: flex;
    align-items: center;
    height: 5rem;
    text-decoration: none;
}
.sideNav-link i{
    min-width: 2rem;
    margin: 0 1.5rem;
}
.link-text{
    display: none;
    margin-left: 1rem;
}
.sideNav:hover{
    width: 16rem;
}
.sideNav:hover .link-text{
    display: block;
}
.sideNav-link:hover{
    background: #007bff;
}
.dash_content{
    margin:8% 10vh 0 20vh;
}
.rdt_TableHeader{
    z-index: -1;
    height: 0px !important;
}
/* Pantallas pequeñas */
@media only screen and (max-width: 600px){
    .sideNav{
        top:auto;
        bottom: 0;
        width: 100%;
        height: 5rem;
    }
    .sideNav:hover{
        width: 100%;
    }
    .sideNav-item:last-child{
        margin-top: inherit;
    }
    .sideNav-link:hover{
        background: inherit;
    }
    .sideNav-link i{
        font-size: 1.4rem;
        margin: 0 1.5rem;
    }
    .sideNav-nav{
        flex-direction: row;
    }
    .sideNav-link{
        justify-content: center;
    }
    main{
        margin:0;
    }
    .sideNav:hover .link-text{
        display: none;
    }
}

/* Pantallas grandes */
@media only screen and (min-width:600px){
    .sideNav{
        width: 6rem;
        height: 100%;
    }
    .sideNav:hover{
        width: 16rem;
    }
    
    .sideNav:hover .link-text{
        display: inline;
    }
}