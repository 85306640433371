@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
a{
  color:rgb(254, 211, 23) !important;
  transition: 0.4s;
}
a:hover{
  color: white !important;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar{
  width:0.3rem;
}
body::-webkit-scrollbar-track{
  background: #fed317;
}
body::-webkit-scrollbar-thumb{
  background: #0f63a8;
}
.Login{
  color:rgb(254, 211, 23) !important;
  transition: 0.4s;
  cursor: pointer;
}
.Login:hover{
  color: white !important;
}
.navbar{
  padding: 0 1rem 0 0 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.dropdown-menu{
  background-color: #03255F !important;
}
.dropdown-item:hover{
  background-color:#006ce0 !important;
}
.bg-primary{
  background-color: #0f63a8 !important;
}
.linkBases{
  color: black !important;
  -webkit-align-content: center;
          align-content: center;
  text-align:center;
  cursor: pointer;
  padding: 5px 0 5px 0;
  font-weight: 500;
}
.linkBases:hover{
  color: rgb(254, 211, 23) !important;
}
.error_code{
    border-color: red !important;
	color: #000;
    outline: 0;
    transition: .5s !important;
}
.good_code{
    border-color: lawngreen !important;
    color: #000;
    outline: 0;
    transition: .5s !important;
}
.error_code{
    border-color: red !important;
	color: #000;
    outline: 0;
    transition: .5s !important;
}
.good_code{
    border-color: lawngreen !important;
    color: #000;
    outline: 0;
    transition: .5s !important;
}
.sideNav{
    top:0;
    width: 6rem;
    height:100%;
    position:fixed;
    background-color: #03255F;
    transition: 200ms ease;
    box-shadow: 10px 0px 8px -5px rgba(0,0,0,0.75);
    z-index: 999;
}
.sideNav a{
    text-decoration: none !important;
}
.sideNav-nav{
    list-style: none;
    padding: 140px 0 0 0;
    margin: 0 ;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    height: 100%;
}
.sideNav-item{
    width: 100%;
    
}
.sideNav-item:last-child{
    margin-top: auto;
}
.sideNav-link{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 5rem;
    text-decoration: none;
}
.sideNav-link i{
    min-width: 2rem;
    margin: 0 1.5rem;
}
.link-text{
    display: none;
    margin-left: 1rem;
}
.sideNav:hover{
    width: 16rem;
}
.sideNav:hover .link-text{
    display: block;
}
.sideNav-link:hover{
    background: #007bff;
}
.dash_content{
    margin:8% 10vh 0 20vh;
}
.rdt_TableHeader{
    z-index: -1;
    height: 0px !important;
}
/* Pantallas pequeñas */
@media only screen and (max-width: 600px){
    .sideNav{
        top:auto;
        bottom: 0;
        width: 100%;
        height: 5rem;
    }
    .sideNav:hover{
        width: 100%;
    }
    .sideNav-item:last-child{
        margin-top: inherit;
    }
    .sideNav-link:hover{
        background: inherit;
    }
    .sideNav-link i{
        font-size: 1.4rem;
        margin: 0 1.5rem;
    }
    .sideNav-nav{
        -webkit-flex-direction: row;
                flex-direction: row;
    }
    .sideNav-link{
        -webkit-justify-content: center;
                justify-content: center;
    }
    main{
        margin:0;
    }
    .sideNav:hover .link-text{
        display: none;
    }
}

/* Pantallas grandes */
@media only screen and (min-width:600px){
    .sideNav{
        width: 6rem;
        height: 100%;
    }
    .sideNav:hover{
        width: 16rem;
    }
    
    .sideNav:hover .link-text{
        display: inline;
    }
}
.HomeContent{
    margin: 8% 0 0 5%;
}
